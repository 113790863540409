var matcher = window.matchMedia('(prefers-color-scheme: dark)');

var lightSchemeIcons = document.querySelectorAll('link.light-favicon');
var darkSchemeIcons = document.querySelectorAll('link.dark-favicon');

matcher.addListener(onUpdate);
onUpdate();


function onUpdate() {
  if (matcher.matches) {
    lightSchemeIcons.forEach(element => {
      element.remove();
    });
    darkSchemeIcons.forEach(element => {
      document.head.append(element);
    });
  } else {
    darkSchemeIcons.forEach(element => {
      element.remove();
    });
    lightSchemeIcons.forEach(element => {
      document.head.append(element);
    });
  }
}
