const activeClass = '-active'
const tabsAndContents = document.querySelectorAll('.js-tab, .tab-content');

const handleClick = (evt) => {
  if(evt.target.classList.contains('js-tab')) {
    [...tabsAndContents].map(item => {
      item.classList.remove(activeClass)
    })
    if (!evt.target.classList.contains(activeClass)) {
      evt.target.classList.add(activeClass)
      document.querySelector(evt.target.getAttribute('href')).classList.add(activeClass)
    }
    evt.preventDefault()
  }
};

document.addEventListener('click', handleClick, false)
