import { tns } from "tiny-slider/src/tiny-slider.module"

{
  tns({
    container: '.js-blockquotes',
    items: 1,
    navPosition: 'bottom',
    controlsContainer: '.js-slider-controls',
  });
}
